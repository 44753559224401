import {AuthInterface} from '@/types/authInterface.ts';
import api from './api';
import {FanDataInterface, ModelDataInterface} from '@/types/userDataInterface';
import axios from 'axios';

export default {
  auth(data: AuthInterface) {
    return axios.post(process.env.VUE_APP_API_URL + '/auth/local', data);
  },
  regFan(data: FormData) {
    return api.post('/users', data);
  },
  regModel(data: FormData) {
    return api.post('/performers/register', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  regModelWithDirectUpload(data) {
    return api.post('/performers/register/with-direct-upload', data);
  },
  regModelUpload(data: FormData) {
    return api.post('/performers/register/upload', data);
  },
  regModelUploadWithDirectUpload(data: {photoUrl: string}) {
    return api.post('/performers/register/upload/with-direct-upload', data);
  },
  me() {
    return api.get('/users/me');
  },
  changePassword(data: any) {
    //TODO no password check for old pass
    return api.put('/users/password', data);
  },
  updateProfile(user: {user: FanDataInterface | ModelDataInterface}) {
    return api.put(`/users/${user.user._id}/update-profile`, user);
  },
  updateModelAvatar(data: FormData) {
    return api.post('/performers/avatar', data);
  },
  updateFanAvatar(data: FormData) {
    return api.post('/users/photo', data);
  },
  updateAvatarDirectUpload(data: {photoUrl: string}) {
    return api.post('/users/photo/with-direct-upload', data);
  },
  confirmEmail(token: string) {
    return api.get('/users/confirm-email', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  resendConfirmEmail(token: string) {
    return api.post(
      '/users/resend-confirm-email',
      {emailVerificationCallbackUrl: `${window.location.origin}/confirm-email`},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  forgotPassword(email: string) {
    return api.post('/users/forgot', {
      changePasswordCallbackUrl: `${window.location.origin}/change-password`,
      email,
    });
  },

  forgotChangePassword(data: {password: string; rePassword: string}, token: string) {
    return api.post('/users/forgot-change-password', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  checkEmail(email: string) {
    return api.post('/users/check-email', {email});
  },
};
