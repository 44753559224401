import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './scss/styles.scss';
import PortalVue from 'portal-vue';
import i18n from '@/plugins/i18n';
import Notifications from 'vue-notification';
import VueGtag from 'vue-gtag';
import vGoogleTranslate from 'v-google-translate';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.VUE_APP_SENTRY_ENV,
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tracingOptions: {
      trackComponents: true,
    },
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    logErrors: true,
    ignoreErrors: [
      'Request failed with status code 429',
      'Request failed with status code 404',
      'Network Error',
      'The play() request was interrupted',
      'Invalid or unexpected token',
      'Unexpected end of input',
      'Unexpected token',
      'SecurityError',
      'NotFoundError',
      'Java exception was raised during method invocation',
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver loop limit exceeded',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });
}

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(Notifications);
Vue.use(vGoogleTranslate);
Vue.use(
  VueGtag,
  {
    config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID},
  },
  router,
);
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
