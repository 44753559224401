import {UserStore} from '@/store/modules/user.ts';
import {FanStore} from '@/store/modules/fan';
import {ModelStore} from '@/store/modules/model';
import {GeneralStore} from '@/store/modules/general';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMultiTabState from 'vuex-multi-tab-state';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    paymentSuccessCount: 0,
  },
  mutations: {
    paymentSuccess(state) {
      state.paymentSuccessCount++;
    },
  },
  modules: {
    user: UserStore.ExtractVuexModule(UserStore),
    general: GeneralStore.ExtractVuexModule(GeneralStore),
    fan: FanStore.ExtractVuexModule(FanStore),
    model: ModelStore.ExtractVuexModule(ModelStore),
  },
  actions: {
    resetStore({commit}) {
      commit('user/reset');
    },
    paymentSuccess({commit}) {
      commit('paymentSuccess');
    },
  },
  strict: process.env.NODE_ENV === 'development',
  plugins: [
    createMultiTabState({statesPaths: ['user']}),
    createPersistedState({
      fetchBeforeUse: true,
      paths: ['user'],
    }),
  ],
});

export const vxm = {
  user: UserStore.CreateProxy(store, UserStore),
  general: GeneralStore.CreateProxy(store, GeneralStore),
  fan: FanStore.CreateProxy(store, FanStore),
  model: ModelStore.CreateProxy(store, ModelStore),
};

export default store;
