import Vue from 'vue';
import Router, {RawLocation, Route} from 'vue-router';
import routes from '@/router/routes';
import {ErrorHandler} from 'vue-router/types/router';

import {checkAccessMiddleware, setPageTitleMiddleware, checkAdult} from './middlewares';

const originalPush = Router.prototype.push;
/*vue-router interface is incompatible with Router.prototype.push overwrite function*/
const pusher = function push(this: Router, location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler): Promise<Route> | void {
  if (onComplete || onAbort) {
   //function: void
   return originalPush.call(this, location, onComplete, onAbort);
  }

  //promise: Promise<Route>
  /*Promise<Route> was changed to any to suppers 'No stacktrace on NavigationDuplicated error'
  * the issue: https://github.com/vuejs/vue-router/issues/2881 */
  return (originalPush.call(this, location) as any)
    .catch((err: Error) => {
    if (Router.isNavigationFailure(err)) {
      //resolve err - return nothing
      return;
    }
    //rethrow error
    return Promise.reject(err);
  })
}

Router.prototype.push = pusher as any;

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({x: 0, y: 0});
        }
      }, 150);
    });
  },
});

router.beforeEach(checkAccessMiddleware);
router.beforeEach(setPageTitleMiddleware);
router.beforeResolve(checkAdult);

export default router;
