export default {
  welcome: 'Welcome!',
  welcomeTo4MyFans: 'Welcome to 4my.fans!',
  dashboard: 'Dashboard',
  statistics: 'Statistics',
  subscriptions: 'Subscriptions',
  details: 'Details',
  'top-videos': 'Top Videos',
  fans: 'fans',
  likes: 'likes',
  'statistic-type': {
    'profile-view': 'Views',
    like: 'Likes',
    comments: 'Comments',
    subscribers: 'Subscriptions',
  },
  'view-all': 'View All',
  views: 'Views',
  email: 'Email',
  gender: 'Gender',
  name: 'Name',
  password: 'Password',
  userName: 'Create User Name',
  modelName: 'Create Model Name',
  modelUserName: 'Create Model Username',
  phoneNumber: 'Phone Number',
  dateOfBirth: {
    title: 'Date of Birth',
    day: 'Day',
    month: 'Month',
    year: 'Year',
  },
  optional: 'optional',
  infant: 'User must be over 18 years old',
  createPassword: 'Create Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  selectGender: 'Select gender and type of content',
  IAm: 'I am',
  selectType: 'Select the type of content that you are interested in',
  payment: 'Payment',
  uploadPhoto: 'Upload profile photo',
  uploadNotate: 'Upload yor profile photo',
  login: 'Log in',
  pleaseLogin: 'Please login to continue',
  loginFan: 'Log in \n as a fan',
  registerFan: 'Register as a Fan',
  registerAsFan: 'Register \n as a fan',
  loginModel: 'Log in \n as a model',
  registerModel: 'Model Registration',
  registerAsModel: 'Register \n as a model',
  forgot: 'I forgot my password',
  or: 'or',
  account: 'ACCOUNT',
  mySubscriptions: 'My Subscriptions',
  myDetails: 'My Details',
  paymentHistory: 'Payment History',
  notifications: 'Notifications',
  directNotifications: 'Direct Messages Notifications',
  notificationsOn: 'ON',
  notificationsOff: 'OFF',
  accountDetails: 'Account Details',
  bio: 'Fill In Your Bio',
  reviewInfo: 'Review Your Information',
  editDetails: 'Edit details',
  upload: 'UPLOAD',
  uploadId1: 'Upload Identification Photo',
  uploadId2: 'Upload Photo Holding Identification',
  filter: 'Filter',
  filters: 'Filters',
  uploaded: 'Uploaded',
  watch: 'WATCH',
  enterNewPassword: 'Please enter your new password',
  passwordHasReset: 'Your password has been reset.',
  identification: {
    title: 'Upload a form of identification that meets the following requirements:',
    text: 'Form of identification must meet the following requirements:',
    listItem1: 'Government issued Identification',
    listItem2: 'Not expired',
    listItem3: 'Includes photo',
    listItem4: 'Full legal name',
    listItem5: 'Date of birth',
    info:
      'Examples: Passport, Driver’s license, Municipal Identification Card (Front and Back), State or Country Identification Card (Front and Back)',
    proceedAgreement: 'To proceed please review and agree to the',
    contentCreator: 'Content Creator Agreement',
    agree: 'AGREE',
  },
  resendEmail: {
    'resend-text-one': 'You are not done yet!',
    'resend-text-two':
      'To finish registration please check your email and follow the link to complete your registration.',
    'fan-title':
      'Last step before you’re ready to start using 4my.fans! We just sent you a verification email to the email address entered.',
    'model-title':
      'Last step before your’e ready to start your 4 My Fans page. We’ve sent you a 6-digit verification code.',
    'fan-text': 'Follow the link from email to finish registration.',
    'model-text': 'Please check your email and enter the code below.',
  },
  agree: {
    text1: 'By selecting your date of birth you are confirming that you are above the age of 18, in agreeance with',
    text2: 'and our',
    terms: 'Terms of Service',
  },
  benefits: {
    learn: "Learn about model's benefits",
    title: 'Model benefits',
    'text-block1': '80% Competitive Payout',
    'text-block2': 'Organized Design',
    'text-block3': 'Dedicated Team to Help Promote and Support Your Page',
    'text-block4': 'Sex Positive Culture',
  },
  custom: 'Custom',
  days: '{count} days',
  profile: {
    /* details */
    reset: 'RESET',
    crop: 'Position and crop your photo',
    'edit-profile': 'Edit Info',
    'manage-payment': 'Manage payment info',
    'add-payment': 'Add payment method',
    'connect-twitter': 'Connect Twitter Account',
    'disconnect-twitter': 'Disconnect Twitter Account',
    'edit-profile-details': 'Edit Profile Details',
    /* subscriptions page */
    period: "{n} month | {n} year | {n} year's",
    'expire-on': 'Expire on',
    'manage-sub': 'Manage My Subscription',
    'sub-details': 'Subscription Details',
    'sub-cancel-dialog': 'Are you sure you want to cancel subscription?',
    /* password change page */
    'change-password': 'Change Password',
    'password-label': 'Old password | New password | Confirm password',
    'password-placeholder': 'Enter password | Enter old password | Enter new password',
    'i-forgot-pass': 'Forgot your password?',
    /* payment page */
    'payment-title': 'Please choose payment plan',
    plan: 'Monthly | Annual',
    'change-plan': 'Change Plan',
    'pay-period': 'per month | per year',
    'billing-details': 'Billing Details',
    'monthly-sub': 'Monthly Subscription',
    'annually-sub': 'Annually Subscription',
    'cancel-sub': 'Cancel Subscription',
  },
  modelPage: {
    all: 'All',
    clips: 'CLIPS',
    videos: 'VIDEOS',
    pics: 'PICS',
    'videos-plus': 'Premium VIDEOS',
    'pics-plus': 'Premium pics',
  },
  paymentOptions: {
    title: 'Pay With',
    method: 'CHOOSE A DIFFERENT PAYMENT METHOD',
    pay: 'PAY',
    cancel: 'CANCEL',
  },
  notFound: {
    sentenceFirst: 'uh oh!',
    sentenceSecond: 'We cannot find the page you are looking for',
    sentenceThird: 'Want to go to our home page instead?',
    sentenceFourth: 'Or … maybe contact us?',
  },
  button: {
    logOut: 'Log Out',
    logout: 'Logout',
    continue: 'Continue',
    save: 'Save',
    cancel: 'Cancel',
    send: 'Send',
    done: 'Done',
    next: 'Next',
    skip: 'skip',
    resend: 'Resend email',
    resendCode: 'Resend code',
    resetFilter: 'Reset Filter',
    apply: 'Apply',
    message: 'Message',
    showMore: 'Show More',
    publish: 'Publish',
    close: 'Close',
    becomeAModel: 'Become a model',
    clickToFindOutMore: 'CLICK TO FIND OUT MORE',
    managePaymentMathod: 'Manage Payment Methods',
    confirm: 'CONFIRM',
    pay: 'PAY',
  },
  tableTh: {
    video: 'Video',
    clips: 'Clips',
    photo: 'Photo',
    description: 'Description',
    added: 'Added',
    publish: 'Publish Date',
    price: 'Price',
    type: 'Type',
    status: 'Status',
    convertStatus: 'Conversion',
    albums: 'Albums',
    numberOfPhotos: 'Photos',
    tags: 'Tags',
  },
  adult: {
    title: 'SENSITIVE CONTENT',
    enter: 'ENTER',
    age: 'By clicking the button below I confirm I am 18 + years old',
    agreeText: 'AGREE',
    agree:
      'I certify that I am at least 18 years of age and have reached the age of maturity within the country that I reside',
    agree2: 'I certify I have read and agree to the',
    agreeNew: 'I certify that I have read and agree to the',
    agreeNew2: 'Terms of the Service Agreement',
    terms: 'This platform is prohibited to individuals under the age of 18 years.',
    'btn-agree': 'YES, I agree',
    'btn-not-agree': 'NO, I do not agree',
  },
  error: {
    'valid-email': 'Please enter a valid email',
    'no-email': 'Please enter a email',
    'no-name': 'Please enter a name',
    'no-username': 'Please enter a username',
    'no-password': 'Please enter a password',
    'no-correct-password':
      'Password must be at least 8 character long and contain at least one uppercase letter, one lowercase letter, one digit number.',
    'not-match': 'OOOPS! Password do not match, please re-enter',
    'min-letters': 'letters at least',
    'no-month': 'Please select month',
    'no-day': 'Please select day',
    'no-year': 'Please select year',
    'no-gender': 'Gender is required field',
    'no-preferences': 'Preferences is required field',
    'no-id1': 'Please upload Identification Photo',
    'no-id2': 'Upload Photo Holding Identification',
    'no-age': 'Please confirm you are 18+ and read our ToS',
    'no-message': 'Please enter a message',
    'invalid-value': 'Please enter a valid value',
    'letters-at-least': '{n} letters at least',
    'max-letters': 'Max {n} letters',
    required: 'The field is required',
    'wrong-confirm-link': 'Wrong password or expired link',
    'invalid-file': 'Invalid file format',
    'invalid-file-description': 'Only media files allowed',
    'max-size': 'Maximum file size exceeded',
    'max-size-description': 'File must be no more than {n}',
    'email-registered': 'This email is already registered',
    'email-registered-model': 'This email is already registered, if you are already a fan use another one.',
    'email-registered-fan': 'This email is already registered, if you are already a model use another one.',
    'username-registered': 'This username is already registered',
    'invalid-login': 'Wrong email or password',
    'captcha-error': 'Please complete the reCAPTCHA verification',
  },
  results: {
    title: 'Results',
    resultsVideos: 'Videos',
    resultsModels: 'Models',
  },
  manage: {
    premiumVideos: 'Premium Videos',
    premiumPhotos: 'Premium Photos',
    clips: 'Clips',
    photos: 'Photos',
  },
  /* tips */
  'from-card': 'From card',
  tips: 'Tips',
  'send-tips': 'Send tips',
  'send-tips-dialog': 'Please confirm Payment of US {tips}',
  'tips-send': 'Your tip to {model} was sent.',
  sendATipTo: 'Send a Tip To',
  male: 'Male',
  female: 'Female',
  MTF: 'MTF',
  FTM: 'FTM',
  gay: 'Gay',
  straight: 'Straight',
  bisexual: 'Bisexual',
  allSexualPreferences: 'All sexual preferences',
  allGenders: 'All',
  recentlyAdded: 'Recently Added',
  mostPopular: 'Most Popular',
  vids: 'Vids',
  'vids-plus': 'Vids +',
  'my-vids-plus': 'My Videos',
  clips: 'Clips',
  pics: 'Pics',
  'pics-plus': 'Pics +',
  shop: 'Shop',
  items: 'items',
  resent: 'resent',
  popularity: 'Popularity',
  relevance: 'Relevance',
  rate: 'Rating',
  newest: 'Newest',
  video: 'video',
  videos: 'Videos',
  model: 'model',
  models: 'Models',
  product: 'product',
  products: 'Products',
  favorites: 'Favorites',
  'my-favorites': 'Favorites',
  noFavorites: "Looks like you don't have any favorites?",
  noFavoritesDescription: 'When you favorite a video you will see it here next time you visit.',
  searchResults: 'Search results for',
  buyNow: 'Buy Now',
  subscription: 'Subscription',
  tip: 'Tip',
  saleVideo: 'Sale Video',
  salePhoto: 'Sale Photo',
  store: 'Store',
  totalGross: 'Total (Gross)',
  totalNet: 'Total (Net)',
  finances: 'Finances',
  cashEarnings: 'Cash Earnings',
  type: 'Type',
  priceGross: 'Price - Gross',
  payoutProcessor: 'Payout Processor',
  TotalPayment: 'Total Payment',
  PayoutCurrency: 'Payout Currency',
  priceNet: 'Price - Net',
  commission: 'Commission',
  date: 'Date',
  editSubscriptionSettings: 'Edit Subscription Settings',
  editBankAccountSettings: 'Edit Bank Account Settings',
  monthlySubscriptionPrice: 'Monthly Subscription Price',
  yearlySubscriptionPrice: 'Yearly Subscription Price',
  seagpaySubscriptionSettings: 'Seagpay Subscription Settings',
  billingHistory: 'Billing History',
  profileLink: 'Profile Link',
  userNameProfile: 'User Name',
  aboutMe: 'About me',
  modelBio: 'Bio',
  age: 'Age',
  languages: 'Languages',
  newLanguage: 'New Language',
  addLanguage: 'Add Language',
  homeTown: 'Home Town',
  ethnicity: 'Ethnicity',
  sexualOrientation: 'Sexual Orientation',
  weight: 'Weight',
  height: 'Height',
  hair: 'Hair',
  bodyHair: 'Body Hair',
  eyes: 'Eyes',
  footSize: 'Foot Size',
  twitter: 'Twitter',
  twitterHandle: 'Twitter',
  instagramHandle: 'Instagram',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullLegalName: 'Full Legal Name',
  photoID: 'Photo ID',
  numberSNN: 'SSN/CPF Number',
  bankName: 'Bank Name',
  bankAccount: 'Bank info',
  rounding: 'Routing Number or Bank Code',
  swiftCode: 'Swift Code',
  swiftCodeUSA: '(if out of the USA)',
  bankAddress: 'Address (of Bank)',
  legalType: 'Legal Type',
  businessTitle: 'Business Title',
  businessTaxID: 'Business Tax ID',
  country: 'Country',
  state: 'State/Province',
  city: 'City',
  address: 'Address',
  mailingAddress: 'Mailing Address',
  zipCode: 'Postal / Zip Code',
  general: 'General',
  topModels: 'Top Models',
  copyright: 'Copyright ©',
  termsOfService: 'Terms of Service, Privacy Policy, DMCA, 2257, Cancellation Affiliate Program',
  seeAll: 'See all',
  noResultsFound: 'No results found',
  noDataAvailable: 'No data available',
  contactUs: 'Contact Us',
  contactSuccess: 'Success! Your message was sent.',
  getInTouch: 'Get in touch',
  contactUsDescription: 'Feel free to contact us about any questions and we will get back to you as soon as possible',
  yourMessage: 'Message',
  bioProfile: 'Bio',
  readBio: 'Read bio',
  leaderboard: 'Leader board',
  schedule: 'Schedule',
  performers: 'Performers',
  tags: 'Tags',
  'text-for-twitter': 'Text for Twitter Video',
  description: 'Description',
  'select-category': 'Select category',
  categories: 'Categories',
  price: 'Price',
  'select-type': 'Select type',
  browse: 'Browse',
  'video-file': 'Video file',
  'upload-video': 'Upload a Video',
  'is-tagged': 'Is Tagged',
  edit: 'Edit',
  'edit-video': 'Edit Video',
  delete: 'Delete',
  chat: 'CHAT',
  messages: 'Messages',
  'my-messages': 'My Messages',
  'no-chats-title': 'Would you like to chat directly with a model?',
  'no-chats-description':
    'After subscribing to a model, you will find your private chats here. Search the model list and subscribe. Private chat with your favorite model today!',
  'no-messages-title': 'No messages, yet',
  'no-messages-description': 'Start your conversation',
  subscribe: 'Subscribe',
  'no-subscribe-message': 'Please subscribe to view {n} media',
  'buy-now': 'BUY',
  status: 'Status',
  active: 'Active',
  'login-to-view-media': 'Login or register to view more media',
  'confirmed-email': 'Your email has been confirmed!',
  'fan-confirmed-email': 'Your email was confirmed and',
  'fan-confirmed-email2': 'You are now ready to browse our website',
  'confirmed-password-change': 'Your password has been changed!',
  'confirmed-password-reset': 'Please check your email to reset your password.',
  'get-started': 'GET STARTED',
  'to-home': 'To Home',
  'view-feed': 'View Feed',
  'model-registration-thanks': 'Thank you for your registration!',
  'model-registration-verify': 'We will verify your information and notify you once active',
  'subscribe-to-view': 'Subscribe to view',
  'buy-to-view': 'Buy to view',
  'reset-password-btn': 'Reset',
  'reset-password': 'Reset Password',
  'reset-password-title': 'Please enter your email to reset your password',
  'email-not-found': 'Email not found',
  'login-title': 'All Member Login',
  all: 'All',
  purchased: 'Purchased',
  oldest: 'Oldest',
  'most-like': 'Most Liked',
  search: 'Search',
  'view-profile': 'View Profile',
  'no-vids-plus': 'Looks like you don’t have any premium videos?',
  'no-vids-plus-description':
    'By subscribing to any model, you will have the opportunity to purchase exclusive video content. Those premium videos purchased will appear here.',
  /* eslint-disable  @typescript-eslint/camelcase */
  sale_video: 'Sale Video',
  sale_photo: 'Purchased Photo',
  monthly_subscription: 'Monthly Subscription',
  yearly_subscription: 'Yearly Subscription',
  performer_subscription: 'Subscription',
  video_purchase: 'Purchased Premium ',
  tip_performer: 'Tip',
  trial_subscription: 'Trial Subscription',
  /* eslint-enable @typescript-eslint/camelcase */
  'profile-link-description': 'Your profile link will be generated from username',
  'subscribe-warning': 'Monthly and Yearly subscriptions are recurring subscriptions',
};
