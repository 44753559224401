import router from '@/router';
import {store, vxm} from '@/store';
import axios from 'axios';
import Vue from 'vue';

const baseDomain = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ``;
const baseURL = `${baseDomain}/api/v1`;

const ax = axios.create({
  baseURL,
});
const noToasterUrls = ['/users/check-email', '/performers/check-performer-username', '/auth/local'];
const noToaster = (error) =>
  noToasterUrls.find((el) => {
    return error.includes(el);
  });

ax.interceptors.request.use((request) => {
  if (vxm.user.token) {
    request.headers['Authorization'] = `Bearer ${vxm.user.token}`;
    return request;
  }
  return request;
});
ax.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if(error.message === 'Network Error') {
      Vue.notify({
        group: 'foo',
        title: 'No Internet connection.',
        duration: 3000,
        type: 'error',
        text: 'Check your network settings and try again.',
      });
      return Promise.reject(error);
    }
    const errorStatus = error.response.status;
    if (error.response && errorStatus === 401) {
      store.dispatch('resetStore').then(() => {
        router.push('/login', () => {
          return;
        });
      });
    }
    // else if (!noToaster(error.config.url)) {
    //   Vue.notify({
    //     group: 'foo',
    //     title: 'Oops!',
    //     duration: 3000,
    //     type: 'error',
    //     text: 'Something went wrong.',
    //   });
    // }
    // if ([400, 403, 500, 503].includes(errorStatus)) {
    //   vxm.general.setServiceUnavailable(true);
    // }
    return Promise.reject(error);
  },
);

export default ax;
