import api from './api';
import axios from 'axios';

export default {
  getMessagesGroups(params) {
    return api.get('/messages/groups', { params });
  },

  createMessagesGroups(data: { recipientId: string; type: string }) {
    return api.post('/messages/groups', data);
  },

  createMessage(data: { content: string; messageGroupId: string }) {
    return api.post('/messages', data);
  },

  createImageMessage(data: { content: string; messageGroupId: string }) {
    return api.post('/messages/image', data);
  },

  createVideoMessage(data: { content: string; messageGroupId: string }) {
    return api.post('/messages/video', data);
  },

  getMessages(id: number, params) {
    return api.get(`/messages/groups/${id}`, { params });
  },

  readMessage(id: string) {
    return api.put(`/messages/${id}/read`);
  },

  readAllMessages(groupId: number, data: { toDate: string }) {
    return api.put(`/messages/${groupId}/readAll`, data);
  },

  getPreSignedPostData(data: { key: string; mediaType: string; type: string }) {
    return api.post('/utils/get-pre-signed-post-data', data);
  },

  directUpload(url: string, data: FormData) {
    return axios.post(url, data);
  },

  paymentSubscription(data) {
    return api.post('/payment/subscription', data);
  },

  paymentVideo(data) {
    return api.post('/payment/video', data);
  },
  directaPayment(data) {
    return api.post('/directa-deposit', data);
  },

  paymentPhoto(data) {
    return api.post('/payment/photo', data);
  },

  paymentProduct(data) {
    return api.post('/payment/product', data);
  },

  paymentTips(data) {
    return api.post('/payment/tips', data);
  },

  paymentCards() {
    return api.post('/payment/cards', { provider: 'ccbill' });
  },

  ordersHistory() {
    return api.get('/orders');
  },
  async directaRegionCheck() {
    //const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    //const ipData = await api.get(proxyUrl + 'https://api.ipify.org?format=json');
    //console.log('ipData ==> ', ipData);
    //if (ipData.data && ipData.data.ip) {
     // return api.get('https://4my.fans/api/v1/redirect-directa-details?ip=' + ipData.data.ip);
    //} else {
      return api.get('https://4my.fans/api/v1/redirect-directa-details');
    //}
  },
};
