import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import modelApi from '@/service/modelApi';
import {PerformerFileResponse} from '@/types/performerFileResponse';
import StatisticsTypes from '@/types/statisticsTypes';
import {StatisticsInterface} from '@/types/statisticsInterface';
import moment from 'moment';
import {ChartDataInterface} from '@/types/chartDataInterface';
import {AxiosResponse} from 'axios';
import {TopVideosParamsInterface} from '@/types/TopVideosParamsInterface';
import {VideosParamsInterface} from '@/types/VideosParamsInterface';
import {AffiliateContentParamsInterface} from '@/types/AffiliateContentParamsInterface';
import {PhotoInterface} from '@/types/photoInterface';
import {vxm} from '@/store';
import {ProductSearchParamsInterface} from '@/types/ProductSearchParamsInterface';
import {ShortVideoInterface, VideoInterface} from '@/types/videoInterface';
import {SubscribersCountInterface} from '@/types/SubscribersCountInterface';
import {PhotosParamsInterface} from '@/types/enums/PhotosParamsInterface';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import {Pagination} from '@/types/Pagination';
import FanApi from '@/service/fanApi';

@Module({namespacedPath: 'model/'})
export class ModelStore extends VuexModule {
  @getter statistic: StatisticsInterface = {};
  @getter photosListLoading = false;
  @getter albumsListLoading = false;
  @getter photosCount = 0;
  @getter albumsCount = 0;
  @getter videosCount = 5;
  @getter photosList: PhotoInterface[] = [];
  @getter albumsList: PhotoInterface[] = [];
  @getter photosListParams = {
    take: 15,
    page: 1,
    performerId: '',
    isForSale: false,
  };
  @getter albumsListParams = {
    take: 15,
    page: 1,
    performerId: '',
    isForSale: true,
  };
  @getter productsListLoading = false;
  @getter productsList = [];
  @getter needUpdatePhotoList = false;
  @getter needUpdateVideoList = false;
  @getter showUploadErrorModal = false;
  @getter uploadErrorActionResolve: Function | null = null;
  @getter uploadErrorAction: Promise<any> | null = null;
  @getter abortUpload = false;

  @mutation setShowUploadErrorModal(data: boolean) {
    this.showUploadErrorModal = data;
    if (data) {
      this.uploadErrorAction = new Promise((resolve) => {
        this.uploadErrorActionResolve = resolve;
      });
    }
  }

  @mutation setAbortUpload(data: boolean) {
    this.abortUpload = data;
  }

  @mutation setNeedUpdateVideoList(data: boolean) {
    this.needUpdateVideoList = data;
  }

  @mutation setNeedUpdatePhotoList(data: boolean) {
    this.needUpdatePhotoList = data;
  }

  @mutation setStatistic(data: {type: StatisticsTypes; res}) {
    const chartData: ChartDataInterface = {
      labels: [],
      datasets: [
        {
          data: [],
        },
      ],
    };
    data.res.forEach((x) => {
      chartData.labels.push(moment(x.start).format('DD.MM'));
      chartData.datasets[0].data.push(x.count);
    });
    this.statistic[data.type] = chartData;
  }

  @mutation setPhotosList(data) {
    this.photosList = this.photosList.concat(data.items);
    this.photosListLoading = false;
    this.photosCount = data.count;
    if (this.photosCount !== this.photosList.length) {
      this.photosListParams.page++;
    }
  }

  @mutation setAlbumsList(data) {
    this.albumsList = data.items;
    this.albumsListLoading = false;
    this.albumsCount = data.count;
  }

  @mutation setVideoCount(val) {
    this.videosCount = val;
  }

  @mutation changeVideoCount(status: 'active' | 'inactive') {
    this.videosCount += status === 'active' ? 1 : -1;
  }

  @mutation setProductsList(data) {
    this.productsList = data.items;
    this.productsListLoading = false;
  }

  @mutation clearPhotosList() {
    this.photosList = [];
    this.photosListParams.page = 1;
    this.photosCount = 0;
  }

  @mutation clearAlbumsList() {
    this.albumsList = [];
    this.albumsCount = 0;
  }

  @mutation setPhotosLoading(data) {
    this.photosListLoading = data;
  }

  @mutation setAlbumsLoading(data) {
    this.albumsListLoading = data;
  }

  @mutation setProductsLoading(data) {
    this.productsListLoading = data;
  }

  @action
  async getPerformerFile(id: string): Promise<AxiosResponse<PerformerFileResponse>> {
    return modelApi.getPerformerFile(id).then((res) => {
      return res;
    });
  }

  @action
  async getStatistic(data: {type: StatisticsTypes; startDate: string; endDate: string}) {
    return modelApi.getStatistic(data).then((res) => {
      this.setStatistic({type: data.type, res: res.data});
      return res.data;
    });
  }

  @action
  async getTopVideos(params: TopVideosParamsInterface) {
    return modelApi.getTopVideos(params).then((res) => {
      return res.data;
    });
  }

  @action
  async getPhoto(id: string) {
    return modelApi.getPhoto(id).then((res) => {
      return res;
    });
  }

  @action
  async getPhotos() {
    const params = JSON.parse(JSON.stringify(this.photosListParams));
    params.performerId = vxm.user.data._id;
    return modelApi.getPhotos(params).then((res) => {
      return res;
    });
  }

  @action
  async getMyPhotos(params: PhotosParamsInterface) {
    return modelApi.getMyPhotos(params).then((res) => {
      vxm.model.setNeedUpdatePhotoList(false);
      return res;
    });
  }

  @action
  async getMyAlbums(params) {
    this.setAlbumsLoading(true);
    return modelApi.getMyPhotos(params).then((res) => {
      this.setAlbumsList(res.data);
      return res;
    });
  }

  @action
  async getProducts(params: ProductSearchParamsInterface) {
    this.setProductsLoading(true);
    return modelApi.getProducts(params).then((res) => {
      this.setProductsList(res.data);
      return res;
    });
  }

  @action
  async getVideos(params: VideosParamsInterface) {
    return modelApi.getVideos(params).then((res) => {
      return res;
    });
  }

  @action
  async getRelatedVideos(id: string) {
    return modelApi.getRelatedVideos(id).then((res) => {
      return res;
    });
  }

  @action
  async getMyVideos(params: VideosParamsInterface) {
    return modelApi.getMyVideos(params).then((res) => {
      vxm.model.setNeedUpdateVideoList(false);
      return res;
    });
  }

  @action
  async getVideo(id: string) {
    return modelApi.getVideo(id).then((res) => {
      return res;
    });
  }

  @action
  async getWorkshopVideo(params: VideosSearchParamsInterface): Promise<AxiosResponse<Pagination<VideoInterface>>> {
    return modelApi.getWorkshopVideo(params).then((res) => {
      return res;
    });
  }

  @action
  async getComments(params: {id: string; type: string}) {
    return modelApi.getComments(params).then((res) => {
      return res;
    });
  }

  @action
  async getAllComments() {
    return modelApi.getAllComments().then((res) => {
      return res;
    });
  }

  @action
  async getAffiliateDetails(id: string) {
    return modelApi.getAffiliateDetails(id).then((res) => {
      return res;
    });
  }

  @action
  async getMyAffiliateContent(params: AffiliateContentParamsInterface) {
    return modelApi.getMyAffiliateContent(params).then((res) => {
      vxm.model.setNeedUpdateVideoList(false);
      return res;
    });
  }

  @action
  async uploadPhoto(params: {data: any; uploadProgressCallback?: Function}) {
    return modelApi.uploadPhoto(params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async updatePhoto(params: {id: string; data: FormData; uploadProgressCallback?: Function}) {
    return modelApi.updatePhoto(params.id, params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async deletePhoto(id) {
    return modelApi.deletePhoto(id).then((res) => {
      return res;
    });
  }

  @action
  async uploadProduct(params: {data: any; uploadProgressCallback?: Function}) {
    return modelApi.uploadProduct(params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async updateProduct(params: {id: string; data: FormData; uploadProgressCallback?: Function}) {
    return modelApi.updateProduct(params.id, params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async deleteProduct(id) {
    return modelApi.deleteProduct(id).then((res) => {
      return res;
    });
  }

  @action
  async uploadVideo(params: {data: any; uploadProgressCallback?: Function}) {
    return modelApi.uploadVideo(params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async updateVideo(params: {id: string; data: FormData; uploadProgressCallback?: Function}) {
    return modelApi.updateVideo(params.id, params.data, params.uploadProgressCallback).then((res) => {
      this.changeVideoCount(res.data.status);
      return res;
    });
  }

  @action
  async updateVideoDirectUpload(params: {id: string; data: ShortVideoInterface; uploadProgressCallback?: Function}) {
    return modelApi.updateVideoDirectUpload(params.id, params.data, params.uploadProgressCallback).then((res) => {
      this.changeVideoCount(res.data.status);
      return res;
    });
  }

  @action
  async deleteVideo(id: string) {
    return modelApi.deleteVideo(id).then((res) => {
      return res;
    });
  }

  @action
  async uploadAffiliateContent(params: {data: FormData; uploadProgressCallback?: Function}) {
    return modelApi.uploadAffiliateContent(params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async updateAffiliateContent(params: {id: string; data: FormData; uploadProgressCallback?: Function}) {
    return modelApi.updateAffiliateContent(params.id, params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async updateAffiliateContentDirectUpload(params: {id: string; data: any; uploadProgressCallback?: Function}) {
    return modelApi
      .updateAffiliateContentDirectUpload(params.id, params.data, params.uploadProgressCallback)
      .then((res) => {
        return res;
      });
  }

  @action
  async deleteAffiliateContent(id: string) {
    return modelApi.deleteAffiliateContent(id).then((res) => {
      return res;
    });
  }

  @action checkUsername(username: string): Promise<AxiosResponse<{username: string | string[]}>> {
    return modelApi.checkUsername(username).then((res) => {
      return res;
    });
  }

  @action uploadWelcomeVideo(data: {welcomeVideo: string}) {
    return modelApi.uploadWelcomeVideo(data).then((res) => {
      return res;
    });
  }

  @action uploadWelcomePhoto(data: {welcomePhoto: string}) {
    return modelApi.uploadWelcomePhoto(data).then((res) => {
      return res;
    });
  }

  @action getEarnings(query = undefined) {
    return modelApi.getEarnings(query).then((res) => {
      return res;
    });
  }
  @action getPayoutHistory(query = undefined) {
    return modelApi.getPayoutHistory(query).then((res) => {
      return res;
    });
  }

  @action getEarningsByPeriod(query = undefined) {
    return modelApi.getEarningsByPeriod(query).then((res) => {
      return res;
    });
  }

  @action connectTwitter(params: {callbackUrl: string}) {
    return modelApi.connectTwitter(params).then((res) => {
      window.location.href = res.data.url;
    });
  }

  @action disconnectTwitter() {
    return modelApi.disconnectTwitter().then((res) => {
      return res;
    });
  }

  @action tweetClip(data: {_id: string; tweetText: string}) {
    return modelApi.tweetClip(data).then((res) => {
      return res;
    });
  }

  @action
  async createBulkMessage(data: {content: string; subscribersType: string}) {
    return modelApi.createBulkMessage(data).then((res) => {
      return res;
    });
  }

  @action
  async createBulkImageMessage(params: {
    data: {file: File; subscribersType: string};
    uploadProgressCallback?: Function;
  }) {
    return modelApi.createBulkImageMessage(params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async createBulkVideoMessage(params: {
    data: {file: File; subscribersType: string};
    uploadProgressCallback?: Function;
  }) {
    return modelApi.createBulkVideoMessage(params.data, params.uploadProgressCallback).then((res) => {
      return res;
    });
  }

  @action
  async getSubscribersCount(): Promise<AxiosResponse<SubscribersCountInterface>> {
    return modelApi.getSubscribersCount().then((res) => {
      return res;
    });
  }
  @action
  async sendPayoutRequest() {
    return modelApi.sendPayoutRequest();
  }
}
