import {RouteConfig} from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/warning',
    name: 'adult-warning-new',
    component: () => import(/* webpackChunkName: "adult-warning-new" */ '@/views/AdultWarningNew.vue'),
    meta: {title: '4MyFans'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('agree') === 'true') {
        next({path: '/fan'});
      } else {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {title: '4MyFans | Login', isLogin: true},
  },
  {
    path: '/content-creator-agreement',
    name: 'content-creator-agreement',
    component: () => import(/* webpackChunkName: "terms-of-services" */ '@/views/ContentCreatorAgreement.vue'),
    meta: {title: '4MyFans | Content Creator Agreement'},
  },
  {
    path: '/terms-of-services',
    name: 'terms-of-services',
    component: () => import(/* webpackChunkName: "terms-of-services" */ '@/views/TermsOfServices.vue'),
    meta: {title: '4MyFans | Terms Of Services'},
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy.vue'),
    meta: {title: '4MyFans | Privacy Policy'},
  },
  {
    path: '/payout-policy',
    name: 'payout-policy',
    component: () => import(/* webpackChunkName: "payout-policy" */ '@/views/PayoutPolicy.vue'),
    meta: {title: '4MyFans | Payout Policy', isAuth: true, access: 'performer'},
  },
  {
    path: '/dmca',
    name: 'DMCA',
    component: () => import(/* webpackChunkName: "dmca" */ '@/views/DMCA.vue'),
    meta: {title: '4MyFans | DMCA'},
  },
  {
    path: '/2257',
    name: '2257',
    component: () => import(/* webpackChunkName: "2257" */ '@/views/RKRCS.vue'),
    meta: {title: '4MyFans | 2257'},
  },
  {
    path: '/user-faq',
    name: 'FanFAQ',
    component: () => import(/* webpackChunkName: "fanfaq" */ '@/views/FanFAQ.vue'),
    meta: {title: '4MyFans | FAQ', access: 'user'},
  },
  {
    path: '/faq',
    name: 'ModelFAQ',
    component: () => import(/* webpackChunkName: "modelfaq" */ '@/views/ModelFAQ.vue'),
    meta: {title: '4MyFans | FAQ', access: 'performer'},
  },
  {
    path: '/payment-processing',
    name: 'payment-processing',
    component: () => import(/* webpackChunkName: "PaymentProcessing" */ '@/views/PaymentProcessing.vue'),
    meta: {title: '4MyFans | Payment processing...'},
  },
  {
    path: '/become/:token',
    name: 'become',
    component: () => import(/* webpackChunkName: "become" */ '@/views/Become.vue'),
    meta: {title: '4MyFans | Become'},
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import(/* webpackChunkName: "confirm-page" */ '@/views/ConfirmPage.vue'),
    meta: {title: '4MyFans | Email Confirmation', isLogin: true},
  },
  {
    path: '/unsubscribe-email',
    name: 'unsubscribe-email',
    component: () => import(/* webpackChunkName: "confirm-page" */ '@/views/ConfirmPage.vue'),
    meta: {title: '4MyFans | Unsubscribe Email'},
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/ChangePassword.vue'),
    meta: {title: '4MyFans | Change Password', isLogin: true},
  },
  {
    path: '/fan',
    name: 'fan',
    component: () => import(/* webpackChunkName: "home" */ '@/layouts/BasicLayout.vue'),
    meta: {title: '4MyFans | My Profile'},
    redirect: {name: 'home'},
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/fan-views/HomeNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Home Feed'},
      },
      {
        path: 'directa-payment',
        name: 'directa-payment',
        component: () => import(/* webpackChunkName: "directa-payment" */ '@/views/fan-views/PaymentSuccess.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Home Feed'},
      },
      {
        path: 'models',
        name: 'models',
        component: () => import(/* webpackChunkName: "models" */ '@/views/fan-views/ModelsNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Models'},
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import(/* webpackChunkName: "messages" */ '@/views/Messages.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Messages'},
      },
      {
        path: 'favorites',
        name: 'favorites',
        component: () => import(/* webpackChunkName: "favorites" */ '@/views/fan-views/FavoritesNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Favorites'},
      },
      {
        path: 'vids',
        name: 'vids-new',
        component: () => import(/* webpackChunkName: "vids-new" */ '@/views/fan-views/VidsNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Vids+'},
      },
      {
        path: 'pics',
        name: 'pics-new',
        component: () => import(/* webpackChunkName: "pics" */ '@/views/fan-views/PicsNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Pics+'},
      },
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "profile-details" */ '@/views/fan-views/profile/Account.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | My Profile'},
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () =>
          import(/* webpackChunkName: "profile-details" */ '@/views/fan-views/profile/MySubscriptions.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | My Subscriptions'},
      },
      {
        path: 'payment-history',
        name: 'payment-history',
        component: () =>
          import(/* webpackChunkName: "payment-history" */ '@/views/fan-views/profile/PaymentHistory.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | My Profile'},
      },
      {
        path: 'search/category',
        name: 'search-category',
        component: () => import(/* webpackChunkName: "search" */ '@/views/fan-views/search-views/SearchNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Search'},
        redirect: {name: 'search-videos-category'},
        children: [
          {
            path: 'videos',
            name: 'search-videos-category',
            component: () =>
              import(/* webpackChunkName: "search-videos" */ '@/views/fan-views/search-views/SearchVideos.vue'),
            meta: {isAuth: true, access: 'user', title: '4MyFans | Search'},
          },
        ],
      },
      {
        path: 'search/:query',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '@/views/fan-views/search-views/SearchNew.vue'),
        meta: {isAuth: true, access: 'user', title: '4MyFans | Search'},
        redirect: {name: 'search-models'},
        props: true,
        children: [
          {
            path: 'models',
            name: 'search-models',
            component: () =>
              import(/* webpackChunkName: "search-models" */ '@/views/fan-views/search-views/SearchModels.vue'),
            meta: {isAuth: true, access: 'user', title: '4MyFans | Search'},
          },
          {
            path: 'videos',
            name: 'search-videos',
            component: () =>
              import(/* webpackChunkName: "search-videos" */ '@/views/fan-views/search-views/SearchVideos.vue'),
            meta: {isAuth: true, access: 'user', title: '4MyFans | Search'},
          },
          // {
          //   path: 'products',
          //   name: 'search-products',
          //   component: () =>
          //     import(/* webpackChunkName: "search-products" */ '@/views/fan-views/search-views/SearchProducts.vue'),
          //   meta: {title: '4MyFans | Search'},
          // },
        ],
      },
    ],
  },
  {
    path: '/model',
    name: 'model',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/BasicLayout.vue'),
    meta: {isAuth: true, access: 'performer', title: '4MyFans | My Profile'},
    redirect: {name: 'dashboard'},
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/model-views/Dashboard.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Dashboard'},
      },
      {
        path: 'tutorials',
        name: 'tutorials',
        component: () => import(/* webpackChunkName: "workshop" */ '@/views/model-views/Tutorials.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Tutorials'},
      },
      {
        path: 'comments',
        name: 'comments',
        component: () => import(/* webpackChunkName: "comments" */ '@/views/model-views/Comments.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Comments'},
      },
      {
        path: 'manage-content',
        name: 'manage-content',
        component: () => import(/* webpackChunkName: "manage-content" */ '@/views/model-views/ManageContentNew.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Manage Content'},
        redirect: {name: 'manage-model-videos'},
        children: [
          {
            path: 'model-videos',
            name: 'manage-model-videos',
            component: () =>
              import(/* webpackChunkName: "model-videos" */ '@/views/model-views/model-details/ModelVideo.vue'),
            props: (route) => ({
              ...route.params,
            }),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Video'},
          },
          {
            path: 'model-tagged-videos',
            name: 'manage-model-tagged-videos',
            component: () =>
              import(/* webpackChunkName: "model-videos" */ '@/views/model-views/model-details/ModelPremiumVideo.vue'),
            props: (route) => ({
              ...route.params,
            }),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Premium Video'},
          },
          {
            path: 'model-clips',
            name: 'manage-model-clips',
            component: () =>
              import(/* webpackChunkName: "model-videos" */ '@/views/model-views/model-details/ModelVideo.vue'),
            props: (route) => ({
              ...route.params,
            }),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Clips'},
          },
          {
            path: 'model-pics',
            name: 'model-pics',
            component: () =>
              import(/* webpackChunkName: "model-pics" */ '@/views/model-views/model-details/ModelPics.vue'),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Pics'},
          },
          {
            path: 'model-albums',
            name: 'model-albums',
            component: () =>
              import(/* webpackChunkName: "model-albums" */ '@/views/model-views/model-details/ModelAlbums.vue'),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Albums'},
          },
          // {
          //   path: 'model-shop',
          //   name: 'model-shop',
          //   component: () =>
          //     import(/* webpackChunkName: "model-shop" */ '@/views/model-views/model-details/ModelShop.vue'),
          //   meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Shop'},
          // },
        ],
      },
      {
        path: 'album/:id',
        name: 'model-albums-details',
        component: () =>
          import(
            /* webpackChunkName: "model-albums-details" */ '@/views/model-views/model-details/ModelAlbumDetail.vue'
          ),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Model Albums'},
      },
      {
        path: 'finance',
        name: 'finance',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/model-views/Finance.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Finance'},
        redirect: {name: 'finance-cash-earnings'},
        children: [
          {
            path: 'cash-earnings',
            name: 'finance-cash-earnings',
            component: () =>
              import(/* webpackChunkName: "finance-cash-earnings" */ '@/views/model-views/CashEarnings.vue'),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Cash Earnings'},
          },
          {
            path: 'statistics',
            name: 'finance-statistics',
            component: () =>
              import(/* webpackChunkName: "finance-statistics" */ '@/views/model-views/FinanceStatistics.vue'),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Satatistics'},
          },
        ],
      },
      {
        path: 'leaderboard',
        name: 'leaderboard',
        component: () => import(/* webpackChunkName: "leaderboard" */ '@/views/model-views/Leaderboard.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Leaderboard'},
      },
      {
        path: 'models',
        name: 'performers',
        component: () => import(/* webpackChunkName: "performers" */ '@/views/fan-views/Models.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Models'},
      },
      {
        path: 'messages',
        name: 'model-messages',
        component: () => import(/* webpackChunkName: "model-messages" */ '@/views/Messages.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Messages'},
      },
      {
        path: 'general',
        name: 'model-profile-general',
        component: () =>
          import(/* webpackChunkName: "model-profile-general" */ '@/views/model-views/profile/ModelProfileGeneral.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | My Profile'},
      },
      {
        path: 'bank-account',
        name: 'model-profile-bank-account',
        component: () =>
          import(
            /* webpackChunkName: "model-profile-bank-account" */ '@/views/model-views/profile/ModelProfileBankAccount.vue'
          ),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | My Profile'},
      },
      {
        path: 'subscription',
        name: 'model-profile-subscription',
        component: () =>
          import(
            /* webpackChunkName: "model-profile-subscription" */ '@/views/model-views/profile/ModelProfileSubscription.vue'
          ),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | My Profile'},
      },
      // {
      //   path: 'profile',
      //   name: 'model-flow-profile',
      //   component: () => import(/* webpackChunkName: "model-flow-profile" */ '@/layouts/BasicLayout.vue'),
      //   meta: {isAuth: true, access: 'performer', title: '4MyFans | My Profile'},
      //   redirect: {name: 'model-profile-general'},
      //   children: [
      //   ],
      // },
      {
        path: 'search/:query',
        name: 'performer-search',
        component: () =>
          import(/* webpackChunkName: "performer-search" */ '@/views/fan-views/search-views/SearchNew.vue'),
        meta: {isAuth: true, access: 'performer', title: '4MyFans | Search'},
        redirect: {name: 'performer-search-models'},
        props: true,
        children: [
          {
            path: 'models',
            name: 'performer-search-models',
            component: () =>
              import(
                /* webpackChunkName: "performer-search-models" */ '@/views/fan-views/search-views/SearchModels.vue'
              ),
            meta: {isAuth: true, access: 'performer', title: '4MyFans | Search'},
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/BasicLayout.vue'),
    redirect: {name: 'adult-warning-new'},
    children: [
      {
        path: 'movie/:id',
        name: 'movie-details',
        component: () => import(/* webpackChunkName: "media-details" */ '@/views/MediaDetail.vue'),
        meta: {title: '4MyFans | Movie Details'},
      },
      {
        path: 'clip/:id',
        name: 'clip-details',
        component: () => import(/* webpackChunkName: "media-details" */ '@/views/MediaDetail.vue'),
        meta: {title: '4MyFans | Clip Details', isAuth: true},
      },
      {
        path: 'photo/:id',
        name: 'photo-details',
        component: () => import(/* webpackChunkName: "media-details" */ '@/views/MediaDetail.vue'),
        meta: {title: '4MyFans | Photo Details', isAuth: true},
      },
      {
        path: 'premium-contents',
        name: 'premium-contents',
        component: () => import('@/views/fan-views/PremiumContents.vue'),
        meta: {isAuth: true, title: '4MyFans | Premium Contents'},
      },
      // Get everything not found
      // { path: '*',
      //   name: 'not-found',
      //   component: () => import('@/views/NotFound.vue'),
      //   meta: {isAuth: true, title: '4MyFans | 404 Page'},
      // },
      {
        path: ':id',
        name: 'model-profile',
        component: () => import(/* webpackChunkName: "model-profile-new" */ '@/views/ModelProfileNew.vue'),
        meta: {access: 'user', title: '4MyFans | Model Profile'},
        redirect: {name: 'model-all'},
        props: true,
        children: [
          {
            path: 'all',
            name: 'model-all',
            meta: {isAuth: false},
            component: () => import(/* webpackChunkName: "model-clips" */ '@/views/model-details/ModelAllNew.vue'),
          },
          {
            path: 'clips',
            name: 'model-clips',
            meta: {isAuth: true},
            component: () => import(/* webpackChunkName: "model-clips" */ '@/views/model-details/ModelClipsNew.vue'),
          },
          {
            path: 'pics',
            name: 'model-pics-public',
            meta: {isAuth: true},
            component: () =>
              import(/* webpackChunkName: "model-pics-public" */ '@/views/model-details/ModelPicsNew.vue'),
          },
          {
            path: 'vids',
            name: 'model-vids',
            meta: {isAuth: true},
            component: () => import(/* webpackChunkName: "model-vids" */ '@/views/model-details/ModelVidsNew.vue'),
          },
          {
            path: 'vids-plus',
            name: 'model-vids-plus',
            meta: {isAuth: true},
            component: () => import(/* webpackChunkName: "model-vids-plus" */ '@/views/model-details/ModelVidsNew.vue'),
          },
          {
            path: 'pics-plus',
            name: 'model-pics-plus',
            meta: {isAuth: true},
            component: () =>
              import(/* webpackChunkName: "model-pics-public" */ '@/views/model-details/ModelPicsNew.vue'),
          },
          // {
          //   path: 'shop',
          //   name: 'model-shop-public',
          //   component: () => import(/* webpackChunkName: "model-shop-public" */ '@/views/model-details/ModelShop.vue'),
          // },
        ],
      },
      {
        path: '*',
        name: 'not-found',
        component: () => import('@/views/NotFound.vue'),
        meta: {isAuth: true, title: '4MyFans | 404 Page'},
      },
    ],
  },
  // { path: '*',
  //   name: 'not-found',
  //   component: () => import('@/views/NotFound.vue'),
  //   meta: {isAuth: true, title: '4MyFans | 404 Page'},
  // }
];

export default routes;
