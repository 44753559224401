import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import {ModelDataInterface} from '@/types/userDataInterface';
import FanApi from '@/service/fanApi';
import {PerformersSearchParamsInterface} from '@/types/PerformersSearchParamsInterface';
import {AffiliateContentParamsInterface} from '@/types/AffiliateContentParamsInterface';
import {AxiosResponse} from 'axios';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import {Pagination} from '@/types/Pagination';
import {PerformersParamsInterface} from '@/types/PerformersParamsInterface';
import {PhotosSearchParamsInterface} from '@/types/PhotosSearchParamsInterface';
import {PhotoInterface} from '@/types/photoInterface';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import {VideoInterface} from '@/types/videoInterface';
import {ProductSearchParamsInterface} from '@/types/ProductSearchParamsInterface';
import {ProductInterface} from '@/types/productInterface';
import {SearchParamsInterface} from '@/types/SearchParamsInterface';
import {VideoCategoriesInterface} from '@/types/VideoCategoriesInterface';
import {PhotosParamsInterface} from '@/types/enums/PhotosParamsInterface';
import GeneralApi from '@/service/generalApi';
import {PerformersMinInfoParamsInterface} from '@/types/PerformersMinInfoParamsInterface';

@Module({namespacedPath: 'fan/'})
export class FanStore extends VuexModule {
  @getter leaderboard: ModelDataInterface[] = [];

  @getter showTipsModal = false;

  @getter modelTotalLikes = 0;

  @mutation modelLikesCounter(data: 1 | -1) {
    this.modelTotalLikes += data;
  }

  @mutation setModalState(data) {
    this.showTipsModal = data.status;
  }

  @mutation setLeaderboard(data: ModelDataInterface[]) {
    this.leaderboard = data;
  }

  @action
  async getLeaderboard(
    params: PerformersSearchParamsInterface,
  ): Promise<AxiosResponse<Pagination<ModelDataInterface>>> {
    return FanApi.getPerformersSearch(params).then((res) => {
      this.setLeaderboard(res.data.items);
      return res;
    });
  }

  @action
  async getAffiliateContentFeed(
    params: AffiliateContentParamsInterface,
  ): Promise<AxiosResponse<Pagination<AffiliateContentResponse>>> {
    return FanApi.getAffiliateContentFeed(params).then((res) => {
      return res;
    });
  }

  @action
  async getAffiliateContentByPerformer(params: {
    page: number;
    take: number;
    performerId: string;
  }): Promise<AxiosResponse<Pagination<AffiliateContentResponse>>> {
    return FanApi.getAffiliateContentByPerformer(params).then((res) => {
      return res;
    });
  }

  @action
  async getTags(keyword: string): Promise<AxiosResponse<Array<string>>> {
    return FanApi.getTags(keyword).then((res) => {
      return res;
    });
  }

  @action
  async getFeaturedContents(params: PerformersParamsInterface): Promise<AxiosResponse<Array<ModelDataInterface>>> {
    return FanApi.getFeaturedContents(params).then((res) => {
      return res;
    });
  }

  @action
  async getPremiumContents(params: PerformersParamsInterface): Promise<AxiosResponse<Array<ModelDataInterface>>> {
    return FanApi.getPremiumContents(params).then((res) => {
      return res;
    });
  }

  @action
  async getPerformers(params: PerformersParamsInterface): Promise<AxiosResponse<Array<ModelDataInterface>>> {
    return FanApi.getPerformers(params).then((res) => {
      return res;
    });
  }

  @action
  async performersMinInfo(params: PerformersMinInfoParamsInterface) {
    return FanApi.performersMinInfo(params).then((res) => {
      return res;
    });
  }

  @action
  async getPerformer(id: string): Promise<AxiosResponse<ModelDataInterface>> {
    return FanApi.getPerformer(id).then((res) => {
      this.modelTotalLikes = res.data.totalLike;
      return res;
    });
  }

  @action
  async getPerformerStats(id: string): Promise<AxiosResponse<ModelDataInterface>> {
    return FanApi.getPerformerStats(id).then((res) => {
      return res;
    });
  }

  @action
  async getPerformerCounts(params): Promise<AxiosResponse<ModelDataInterface>> {
    return FanApi.getPerformerCounts(params).then((res) => {
      return res;
    });
  }

  @action
  async performerView(id: string) {
    return FanApi.performerView(id).then((res) => {
      return res;
    });
  }

  @action
  async getPhotos(params: PhotosParamsInterface): Promise<AxiosResponse<PhotoInterface | number>> {
    return FanApi.getPhotos(params).then((res) => {
      return res;
    });
  }

  @action
  async getPhotosSearch(params: PhotosSearchParamsInterface): Promise<AxiosResponse<Pagination<PhotoInterface>>> {
    return FanApi.getPhotosSearch(params).then((res) => {
      return res;
    });
  }

  @action
  async getVideos(params: VideosSearchParamsInterface): Promise<AxiosResponse<VideoInterface | number>> {
    return FanApi.getVideos(params).then((res) => {
      return res;
    });
  }

  @action
  async getVideosSearch(params: VideosSearchParamsInterface): Promise<AxiosResponse<Pagination<VideoInterface>>> {
    return FanApi.getVideosSearch(params).then((res) => {
      return res;
    });
  }

  @action
  async addToFavorite(data: {entityId: string; entityType: string}) {
    return FanApi.addToFavorite({...data, ...{type: 'favorites'}}).then((res) => {
      return res;
    });
  }

  @action
  async removeFromFavorite(data: {entityId: string; entityType: string}) {
    return FanApi.removeFromFavorite({...data, ...{type: 'favorites'}}).then((res) => {
      return res;
    });
  }

  @action
  async getFavorites(params: {page: number; take: number; type: string; entityType?: string}) {
    return FanApi.getFavorites(params).then((res) => {
      return res;
    });
  }

  // TODO: add interfaces after swagger info update
  @action
  async getPurchasedVideos(params) {
    return FanApi.getPurchasedVideos(params).then((res) => {
      return res;
    });
  }

  @action
  async getProductsSearch(params: ProductSearchParamsInterface): Promise<AxiosResponse<Pagination<ProductInterface>>> {
    return FanApi.getProductsSearch(params).then((res) => {
      return res;
    });
  }

  @action
  async search(
    params: SearchParamsInterface,
  ): Promise<AxiosResponse<Pagination<ProductInterface | ModelDataInterface | VideoInterface>>> {
    return FanApi.search(params).then((res) => {
      return res;
    });
  }

  @action
  async searchPerformerMedia(params: {
    page: string;
    take: string;
    performerId: string;
  }): Promise<AxiosResponse<Pagination<AffiliateContentResponse | PhotoInterface | VideoInterface>>> {
    return FanApi.searchPerformerMedia(params).then((res) => {
      return res;
    });
  }

  @action
  async searchPerformerMediaForGuestUser(params: {
    page: string;
    take: string;
    performerId: string;
  }): Promise<AxiosResponse<Pagination<AffiliateContentResponse | PhotoInterface | VideoInterface>>> {
    return FanApi.searchPerformerMediaForGuestUser(params).then((res) => {
      return res;
    });
  }

  @action
  async searchSubscribedMedia(params: {
    page: string;
    take: string;
  }): Promise<AxiosResponse<Pagination<AffiliateContentResponse | PhotoInterface | VideoInterface>>> {
    return FanApi.searchSubscribedMedia(params).then((res) => {
      return res;
    });
  }

  @action
  async searchStats(params: {
    q: string;
    performerId: string;
  }): Promise<
    AxiosResponse<{
      product: number;
      performer: number;
      video: number;
    }>
  > {
    return FanApi.searchStats(params).then((res) => {
      return res;
    });
  }

  @action
  async getCategories(): Promise<AxiosResponse<Array<VideoCategoriesInterface>>> {
    return FanApi.getCategories().then((res) => {
      return res;
    });
  }

  @action
  async likePhoto(id: string) {
    return FanApi.likePhoto(id).then((res) => res);
  }

  @action
  async like(data: {entityId: string; entityType: string}) {
    return FanApi.like(data).then((res) => res);
  }

  @action
  async unlike(data: {entityId: string; entityType: string}) {
    return FanApi.unlike(data).then((res) => res);
  }

  @action
  async ordersHistory() {
    return GeneralApi.ordersHistory().then((res) => {
      return res;
    });
  }

  @action
  async setVideoView(id: string) {
    return FanApi.setVideoView(id).then((res) => {
      return res;
    });
  }

  @action
  async cancelSubscription(id: string) {
    return FanApi.cancelSubscription(id).then((res) => {
      return res;
    });
  }
}
