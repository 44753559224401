import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import {AxiosResponse} from 'axios';
import {Pagination} from '@/types/Pagination';
import {MessagesGroupInterface} from '@/types/messagesGroupInterface';
import GeneralApi from '@/service/generalApi';
import {MessageInterface} from '@/types/messageInterface';
import {vxm} from '@/store';

@Module({namespacedPath: 'general/'})
export class GeneralStore extends VuexModule {
  @getter hasUnreadSubscriberMessages = false;
  @getter hasUnreadModelMessages = false;
  @getter serviceUnavailableError = false;

  @mutation setUnreadSubscriberMessages(value: boolean) {
    this.hasUnreadSubscriberMessages = value;
  }

  @mutation setUnreadModelMessages(value: boolean) {
    this.hasUnreadModelMessages = value;
  }

  @mutation setServiceUnavailable(value: boolean) {
    this.serviceUnavailableError = value;
  }

  @action
  async getMessagesGroups(params): Promise<AxiosResponse<MessagesGroupInterface[]>> {
    return GeneralApi.getMessagesGroups(params).then((res) => {
      return res;
    });
  }

  @action
  async createMessagesGroups(data: {
    recipientId: string;
    type: string;
  }): Promise<AxiosResponse<MessagesGroupInterface>> {
    return GeneralApi.createMessagesGroups(data).then((res) => {
      return res;
    });
  }

  @action
  async createMessage(data: {content: string; messageGroupId: string}): Promise<AxiosResponse<MessageInterface>> {
    return GeneralApi.createMessage(data).then((res) => {
      return res;
    });
  }

  @action
  async createImageMessage(data: {content: string; messageGroupId: string}): Promise<AxiosResponse<MessageInterface>> {
    return GeneralApi.createImageMessage(data).then((res) => {
      return res;
    });
  }

  @action
  async createVideoMessage(data: {content: string; messageGroupId: string}): Promise<AxiosResponse<MessageInterface>> {
    return GeneralApi.createVideoMessage(data).then((res) => {
      return res;
    });
  }

  @action
  async getMessages(payload): Promise<AxiosResponse<Pagination<MessageInterface>>> {
    return GeneralApi.getMessages(payload.id, payload.params).then((res) => {
      return res;
    });
  }

  @action
  async readMessage(id: string) {
    return GeneralApi.readMessage(id).then((res) => {
      return res;
    });
  }

  @action
  async readAllMessages(payload) {
    return GeneralApi.readAllMessages(payload.groupId, payload.data).then((res) => {
      return res;
    });
  }

  @action
  async getPreSignedPostData(data: {key: string; mediaType: string; type: string}) {
    return GeneralApi.getPreSignedPostData(data).then((res) => {
      return res;
    });
  }

  @action
  async directUpload(payload: {url: string; data: FormData}) {
    return GeneralApi.directUpload(payload.url, payload.data).then((res) => {
      return res;
    });
  }

  @action
  async checkHasUnreadMessages() {
    this.checkHasUnreadMessagesForType('model').then((res) => {
      this.setUnreadModelMessages(res);
    });

    if (vxm.user.role === 'performer') {
      this.checkHasUnreadMessagesForType('subscriber').then((res) => {
        this.setUnreadSubscriberMessages(res);
      });
    }
  }

  @action
  async checkHasUnreadMessagesForType(type: string) {
    const params = {
      page: 1,
      take: 1,
      sortRead: -1,
      type,
    };
    return this.getMessagesGroups(params).then((res) => {
      let hasUnreadMessages = false;

      if (res.data.length) {
        const contact = res.data[0];
        if (contact.lastSenderId === contact?.recipient?._id && contact.notSeenMessages) {
          hasUnreadMessages = true;
        }
      }

      return hasUnreadMessages;
    });
  }
}
