/**
 * Check access permission to auth routes
 */
import {Route} from 'vue-router';
import {vxm} from '@/store';

export function checkAccessMiddleware(to: Route, from: Route, next: Function) {
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth);
  const isLoginRoute = to.matched.some((item) => item.meta.isLogin);
  if (vxm.user.token) {
    if (isLoginRoute) {
      return next({path: vxm.user.role === 'user' ? '/fan' : '/model'});
    }
    if (!to.meta.access || to.meta.access === vxm.user.role) {
      if (to.path === '/') {
        return next({path: vxm.user.role === 'user' ? '/fan' : '/model'});
      }
      return next();
    } else {
      if (vxm.user.role && vxm.user.role !== 'admin') {
        //performer, user, admin, ''
        return next({path: vxm.user.role === 'user' ? '/fan' : '/model'});
      }
      return next({path: '/login'});
    }
  }
  if (isAuthRoute) {
    if (!vxm.user.inputLink) {
      vxm.user.inputLink = to.fullPath;
    }
    return next({path: '/login'});
    // return next({path: '/not-found'});
  }
  return next();
}

export function setPageTitleMiddleware(to: Route, from: Route, next: Function) {
  const pageTitle = [...to.matched].reverse().find((item: any) => item.meta.title);
  if (pageTitle) {
    window.document.title = pageTitle.meta.title;
  }
  return next();
}

export function checkAdult(to: Route, from: Route, next: Function) {
  const links = [
    '/warning',
    '/confirm-email',
    '/unsubscribe-email',
    '/change-password',
    '/privacy-policy',
    '/terms-of-services',
    '/not-found',
  ];
  if (localStorage.getItem('agree') !== 'true' && !links.includes(to.path)) {
    if (!vxm.user.inputLink) {
      vxm.user.inputLink = to.fullPath;
    }
    return next({path: '/warning'});
  } else {
    return next();
  }
}
