










import {Component, Vue, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import {MessageInterface} from '@/types/messageInterface';

Vue.use(
  new VueSocketIO({
    connection: SocketIO(process.env.VUE_APP_API_URL, {
      path: '/socket.io-client',
      query: {token: vxm.user.token},
      transports: ['websocket', 'polling'],
      autoConnect: false,
    }),
  }),
);

@Component({
  components: {},
  sockets: {
    connect: function() {
      this.$socket.emit('context');
    },
  },
})
export default class App extends Vue {
  key = 1;

  get userId() {
    return vxm.user.data._id;
  }

  @Watch('userId') onIdChanged(newVal, oldVal) {
    if (newVal != sessionStorage.getItem('id')) {
      this.$router.push({path: newVal ? (vxm.user.role === 'user' ? '/fan' : '/model') : '/login'});
    }
  }

  mounted() {
    if (vxm.user.token && vxm.user.data.role === 'user') {
      vxm.user.paymentCards().catch((error) => {
        return error;
      });
      vxm.user.getSubscriberBills().catch((error) => {
        return error;
      });
    }

    if (vxm.user.token) {
      this.$socket.connect();
    }

    this.sockets.subscribe('NEW_MESSAGE', (message: MessageInterface) => {
      if (this.$route.path !== '/model/messages' && this.$route.path !== '/fan/messages') {
        const messageText = message.type === 'text' ? message.content : 'Shared media';
        const roleLink = vxm.user.role === 'performer' ? 'model' : 'fan';
        const chatType = message.senderRole === 'user' ? 'subscriber' : 'model';
        const messageTemplate = `<a href="/${roleLink}/messages?chatId=${message.messageGroupId}&chatType=${chatType}"><div class="text">${messageText}</div><button class="btn" type="button">View</button></a>`;
        this.$notify({
          group: 'foo',
          title: 'New message',
          duration: 6000,
          type: 'message',
          text: messageTemplate,
        });
        chatType === 'model' ? vxm.general.setUnreadModelMessages(true) : vxm.general.setUnreadSubscriberMessages(true);
      }
    });

    this.sockets.subscribe('connect_error', () => {
      if (this.$socket.io.opts.transports[0] === 'websocket') {
        this.$socket.io.opts.transports = ['polling', 'websocket'];
        this.$socket.connect();
      }
    });
  }

  created() {
    this.checkNewBuild();
    setInterval(this.checkNewBuild, 1000 * 60 * 60);
  }
  checkNewBuild() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/?_=' + new Date().getTime());
    xhr.send();
    xhr.onreadystatechange = function() {
      if (this.readyState == this.HEADERS_RECEIVED) {
        const headers = xhr.getAllResponseHeaders();
        const arr = headers.trim().split(/[\r\n]+/);
        const headerMap = {};
        arr.forEach(function(line) {
          const parts = line.split(': ');
          const header = parts.shift() as string;
          const value = parts.join(': ');
          headerMap[header] = value;
        });
        if (headerMap['last-modified']) {
          if (
            !localStorage.getItem('last-modified') ||
            (localStorage.getItem('last-modified') &&
              localStorage.getItem('last-modified') != headerMap['last-modified'])
          ) {
            localStorage.setItem('last-modified', headerMap['last-modified']);
            window.location.reload();
          }
        }
      }
    };
  }
}
