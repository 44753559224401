import api from './api';
import {PerformersSearchParamsInterface} from '@/types/PerformersSearchParamsInterface';
import {AffiliateContentParamsInterface} from '@/types/AffiliateContentParamsInterface';
import {PerformersParamsInterface} from '@/types/PerformersParamsInterface';
import {PhotosSearchParamsInterface} from '@/types/PhotosSearchParamsInterface';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import {ProductSearchParamsInterface} from '@/types/ProductSearchParamsInterface';
import {SearchParamsInterface} from '@/types/SearchParamsInterface';
import {PerformersMinInfoParamsInterface} from '@/types/PerformersMinInfoParamsInterface';

export default {
  leaderboards(params) {
    return api.get('/performers/leaderboards', {params});
  },

  getFeaturedContents(params: PerformersParamsInterface = {}) {
    return api.get('/performers/featured', {params});
  },

  getAffiliateContentFeed(params: AffiliateContentParamsInterface) {
    return api.get('/affiliateContent/public-feed', {params});
  },

  getAffiliateContentByPerformer(params: {page: number; take: number; performerId: string}) {
    return api.get('/affiliateContent/performer-public-feed', {params});
  },

  getTags(keyword: string) {
    return api.get('/tags/search', {params: {keyword: keyword}});
  },

  getPerformers(params: PerformersParamsInterface = {}) {
    return api.get('/performers/search', {params});
  },

  performersMinInfo(params: PerformersMinInfoParamsInterface) {
    return api.get('/performers', {params});
  },

  getPerformersSearch(params: PerformersSearchParamsInterface = {}) {
    return api.get('/performers/search', {params});
  },

  getPerformer(id) {
    return api.get(`/performers/${id}`);
  },

  getPerformerStats(id) {
    return api.get(`/performers/${id}/media/totals`);
  },

  getPerformerCounts(params) {
    return api.get(`/performers/gender-count`);
  },

  performerView(id) {
    return api.put(`/performers/${id}/view`);
  },

  getPhotos(params) {
    return api.get('/photos', {params});
  },

  getPhotosSearch(params: PhotosSearchParamsInterface = {}) {
    return api.get('/photos/search', {params});
  },

  getVideos(params) {
    return api.get('/videos', {params});
  },

  getVideosSearch(params: VideosSearchParamsInterface = {}) {
    return api.get('/videos/search', {params});
  },

  getPremiumContents(params: PerformersParamsInterface = {}) {
    return api.get('/videos/search', {params});
  },

  addToFavorite(data: {entityId: string; entityType: string}) {
    return api.post('/saved', data);
  },

  removeFromFavorite(data: {entityId: string; entityType: string}) {
    return api.delete('/saved', {params: data});
  },

  getFavorites(params: {page: number; take: number; type: string; entityType?: string}) {
    return api.get('/search/saved', {params});
  },

  getPurchasedVideos(params = {}) {
    return api.get('/earning/search', {params});
  },

  getProductsSearch(params: ProductSearchParamsInterface = {}) {
    return api.get('/products/search/store', {params});
  },

  getSubscriberBills() {
    return api.get(`users/subscribers/performers`);
  },

  getMySubscriptions(params: {take: string; page: string} = {take: '', page: ''}) {
    return api.get('subscriptions/subscribers/performers', {params});
  },

  search(params: SearchParamsInterface) {
    return api.get('/search', {params});
  },

  searchPerformerMedia(params: {page: string; take: string; performerId: string}) {
    return api.get('/search/performer/media', {params});
  },

  searchPerformerMediaForGuestUser(params: {page: string; take: string; performerId: string}) {
    return api.get('/search/performer/guest-media', {params});
  },

  searchSubscribedMedia(params: {page: string; take: string}) {
    return api.get('/search/subscribed/media', {params});
  },

  searchStats(params: {q: string; performerId: string}) {
    return api.get('/search/stats', {params});
  },

  getCategories() {
    return api.get('/categories');
  },

  contactUs(data) {
    return api.post('/settings/contact', data);
  },

  likePhoto(id) {
    return api.post('/photos/like', id);
  },
  setVideoView(id) {
    return api.put(`/videos/${id}/view`);
  },
  like(data: {entityId: string; entityType: string}) {
    return api.post('/like', data);
  },
  unlike(data: {entityId: string; entityType: string}) {
    return api.post('/unlike', data);
  },
  sendComment(data: {content: string; photo?: string; video?: string}) {
    return api.post('/comments', data);
  },
  deleteComment(id) {
    return api.delete(`/comments/${id}`);
  },
  cancelSubscription(id: string) {
    return api.post('/payment/subscription/cancel', {subscriptionId: id});
  },
};
