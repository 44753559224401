import OauthApi from '@/service/oauthApi';
import {AuthInterface} from '@/types/authInterface.ts';
import {FanDataInterface, ModelDataInterface} from '@/types/userDataInterface.ts';
import {action, getter, Module, mutation, VuexModule} from 'vuex-class-component';
import fanApi from '@/service/fanApi';
import GeneralApi from '@/service/generalApi';

@Module({namespacedPath: 'user/'})
export class UserStore extends VuexModule {
  @getter token = '';
  @getter role = '';
  @getter data: FanDataInterface | ModelDataInterface = {} as FanDataInterface;
  @getter CCBillFormUrl = '';
  @getter transactionId = '';
  @getter userCards = [];
  @getter subscriptions = [];
  @getter inputLink = '';

  @mutation setAuthToken(token: string) {
    this.token = token;
  }

  @mutation setUserData(data: FanDataInterface | ModelDataInterface) {
    this.data = data;
    this.role = data.role;
    sessionStorage.setItem('id', data._id);
  }

  @mutation setCCBill(data: {redirectUrl: string; transactionId?: string}) {
    this.CCBillFormUrl = data.redirectUrl;
    this.transactionId = data.transactionId ? data.transactionId : '';
  }

  @mutation setUserCards(data) {
    this.userCards = data;
  }

  @mutation setSubscriptions(data) {
    this.subscriptions = data.data;
  }

  @mutation reset() {
    this.data = {} as FanDataInterface;
    this.role = '';
    this.token = '';
    this.CCBillFormUrl = '';
    this.transactionId = '';
    this.userCards = [];
    this.subscriptions = [];
    this.inputLink = '';
  }

  @action
  async auth(data: AuthInterface) {
    return OauthApi.auth(data).then((res) => {
      this.setAuthToken(res.data.token);
      return res;
    });
  }

  @action
  async registerFan(data: FormData) {
    return OauthApi.regFan(data).then((res) => {
      return res;
    });
  }

  @action
  async registerModel(data: any) {
    return OauthApi.regModel(data).then((res) => {
      return res;
    });
  }

  @action
  async registerModelWithDirectUpload(data: any) {
    return OauthApi.regModelWithDirectUpload(data).then((res) => {
      return res;
    });
  }

  @action
  async registerModelUpload(data: any) {
    return OauthApi.regModelUpload(data).then((res) => {
      return res;
    });
  }

  @action
  async registerModelUploadWithDirectUpload(data: {photoUrl: string}) {
    return OauthApi.regModelUploadWithDirectUpload(data).then((res) => {
      return res;
    });
  }

  @action
  async getMe() {
    return OauthApi.me().then((res) => {
      this.setUserData(res.data);
      return res;
    });
  }

  @action
  async updateProfile(user: {user: FanDataInterface | ModelDataInterface}) {
    return OauthApi.updateProfile(user).then((res) => {
      this.setUserData(res.data);
      return res;
    });
  }

  @action
  async changePassword(data: any) {
    return OauthApi.changePassword(data).then((res) => {
      return res;
    });
  }

  @action
  async updateModelAvatar(data: FormData) {
    return OauthApi.updateModelAvatar(data).then((res) => {
      return res;
    });
  }

  @action
  async updateFanAvatar(data: FormData) {
    return OauthApi.updateFanAvatar(data).then((res) => {
      return res;
    });
  }

  @action
  async updateAvatarDirectUpload(data: {photoUrl: string}) {
    return OauthApi.updateAvatarDirectUpload(data).then((res) => {
      return res;
    });
  }

  @action
  async getSubscriberBills() {
    return fanApi.getSubscriberBills().then((res) => {
      this.setSubscriptions(res);
      return res;
    });
  }

  @action
  async mySubscriptions(data: {take: string; page: string}) {
    return fanApi.getMySubscriptions(data).then((res) => {
      return res;
    });
  }

  @action
  async contactUs(data) {
    return fanApi.contactUs(data).then((res) => {
      return res;
    });
  }

  @action
  async confirmEmail(token: string) {
    return OauthApi.confirmEmail(token).then((res) => {
      return res;
    });
  }

  @action
  async resendConfirmEmail(token: string) {
    return OauthApi.resendConfirmEmail(token).then((res) => {
      return res;
    });
  }

  @action
  async forgotPassword(email: string) {
    return OauthApi.forgotPassword(email).then((res) => {
      return res;
    });
  }

  @action
  async forgotChangePassword(data: {password: string; rePassword: string; token: string}) {
    return OauthApi.forgotChangePassword(
      {
        password: data.password,
        rePassword: data.rePassword,
      },
      data.token,
    ).then((res) => {
      return res;
    });
  }

  @action
  async checkEmail(email: string) {
    return OauthApi.checkEmail(email).then((res) => {
      return res;
    });
  }

  @action
  async paymentSubscription(data) {
    return GeneralApi.paymentSubscription(data).then((res) => {
      this.setCCBill({redirectUrl: res.data.redirectUrl, transactionId: res.data.transactionId});
      return res;
    });
  }

  @action
  async paymentVideo(data) {
    return GeneralApi.paymentVideo(data).then((res) => {
      this.setCCBill({redirectUrl: res.data.redirectUrl, transactionId: res.data.transactionId});
      return res;
    });
  }

  @action
  async paymentPhoto(data) {
    return GeneralApi.paymentPhoto(data).then((res) => {
      this.setCCBill({redirectUrl: res.data.redirectUrl, transactionId: res.data.transactionId});
      return res;
    });
  }
  @action
  async directaPayment(data) {
    return GeneralApi.directaPayment(data).then((res) => {
      // this.setCCBill({redirectUrl: res.data.redirectUrl, transactionId: res.data.transactionId});
      return res;
    });
  }

  @action
  async paymentProduct(data) {
    return GeneralApi.paymentProduct(data).then((res) => {
      this.setCCBill({redirectUrl: res.data.redirectUrl, transactionId: res.data.transactionId});
      return res;
    });
  }

  @action
  async paymentTips(data) {
    return GeneralApi.paymentTips(data).then((res) => {
      this.setCCBill({redirectUrl: res.data.redirectUrl, transactionId: res.data.transactionId});
      return res;
    });
  }

  @action
  async paymentCards() {
    return GeneralApi.paymentCards().then((res) => {
      this.setUserCards(res.data);
      return res;
    });
  }

  @action sendComment(data: {content: string; photo?: string; video?: string}) {
    return fanApi.sendComment(data).then((res) => {
      return res;
    });
  }

  @action
  async deleteComment(id) {
    return fanApi.deleteComment(id).then((res) => {
      return res;
    });
  }
}
