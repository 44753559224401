import moment from 'moment';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';

Vue.use(VueI18n);
const messages = {
  en: en,
};
const i18n = new VueI18n({
  locale: 'en',
  messages,
});

if (localStorage.locale) {
  i18n.locale = localStorage.locale;
} else {
  localStorage.setItem('locale', i18n.locale);
}
moment.locale(i18n.locale);
export default i18n;
