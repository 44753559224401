import api from './api';
import StatisticsTypes from '@/types/statisticsTypes';
import {TopVideosParamsInterface} from '@/types/TopVideosParamsInterface';
import {VideosParamsInterface} from '@/types/VideosParamsInterface';
import {AffiliateContentParamsInterface} from '@/types/AffiliateContentParamsInterface';
import {PhotosSearchParamsInterface} from '@/types/PhotosSearchParamsInterface';
import {ProductSearchParamsInterface} from '@/types/ProductSearchParamsInterface';
import {PreSignedPostInterface} from '@/types/PreSignedPostInterface';
import axios, {AxiosResponse} from 'axios';
import {ShortVideoInterface} from '@/types/videoInterface';
import {SubscribersCountInterface} from '@/types/SubscribersCountInterface';
import {vxm} from '@/store';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';

export default {
  getPerformerFile(id: string) {
    return api.get(`/performers/files/${id}`);
  },
  getStatistic(data: {type: StatisticsTypes; startDate: string; endDate: string}) {
    return api.get(`/stats/${data.type}`, {params: {startDate: data.startDate, endDate: data.endDate}});
  },
  getTopVideos(params: TopVideosParamsInterface) {
    return api.get('/videos/topvideos', {params: params});
  },
  getPhoto(id: string) {
    return api.get(`/photos/${id}`);
  },
  getPhotos(params: PhotosSearchParamsInterface) {
    return api.get('/photos/search', {params: params});
  },
  getMyPhotos(params: PhotosSearchParamsInterface) {
    return api.get('/photos/search/my', {params: params});
  },
  getProducts(params: ProductSearchParamsInterface) {
    return api.get('/products/search/store', {params: params});
  },
  getVideos(params: VideosParamsInterface) {
    return api.get('/videos/search', {params: params});
  },
  getRelatedVideos(id: string) {
    return api.get(`/videos/${id}/related`);
  },
  getMyVideos(params: VideosParamsInterface) {
    return api.get('/videos/search/my', {params: params});
  },
  getVideo(id: string) {
    return api.get(`/videos/${id}`);
  },
  getWorkshopVideo(params: VideosSearchParamsInterface = {}) {
    return api.get('/workshop-videos/search', {params});
  },
  getComments(params: {id: string; type: string}) {
    return api.get(`/comments/${params.type}/${params.id}`);
  },
  getAllComments() {
    return api.get(`comments`);
  },
  getAffiliateDetails(id: string) {
    return api.get(`/affiliateContent/${id}`);
  },
  getMyAffiliateContent(params: AffiliateContentParamsInterface) {
    return api.get('/affiliateContent/search/my', {params: params});
  },
  async uploadPhoto(data: any, uploadProgressCallback?: Function) {
    const config = {} as any;
    const preSignedArray: Array<any> = [];
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    if (data.file && data.file.length) {
      for (let i = 0; i <= data.file.length - 1; i++) {
        preSignedArray.push(
          this.getPreSignedPostData(
            {
              key: data.file[i].name,
              type: 'photos.photo',
              mediaType: data.file[i].type,
            },
            data.file[i],
            config,
          ),
        );
      }
      await Promise.all(preSignedArray).then((values) => {
        data.photoUrls = values;
      });
    }
    delete data.file;
    if (data._id) {
      return api.patch(`/photos/with-direct-upload/${data._id}`, data);
    } else {
      return api.post('/photos/with-direct-upload', data);
    }
  },
  updatePhoto(id, data: FormData, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return api.patch(`/photos/${id}`, data, config);
  },
  deletePhoto(id) {
    return api.delete(`/photos/${id}`);
  },
  uploadProduct(data: any, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return this.getPreSignedPostData(
      {
        key: data.file.name,
        type: 'product.photo',
        mediaType: data.file.type,
      },
      data.file,
      config,
    ).then((res) => {
      delete data.file;
      data.photoUrl = res;
      return api.post('/products/with-direct-upload', data);
    });
  },
  updateProduct(id, data: FormData, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return api.put(`/products/${id}`, data, config);
  },
  deleteProduct(id) {
    return api.delete(`/products/${id}`);
  },
  uploadVideo(data: any, uploadProgressCallback?: Function) {
    return this.s3FileUpload(
      {
        key: data.file.fileFullPath.name,
        type: 'videos.video',
        mediaType: data.file.fileFullPath.type,
      },
      data.file.fileFullPath,
      uploadProgressCallback,
    ).then((res) => {
      if (res) {
        delete data.file;
        data.videoUrl = res;
        return api.post('/videos/direct-upload', data);
      } else {
        return Promise.reject();
      }
    });
  },
  updateVideo(id, data: FormData, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return api.patch(`/videos/${id}`, data, config);
  },
  async updateVideoDirectUpload(id, data: ShortVideoInterface, uploadProgressCallback?: Function) {
    if (data.file.fileFullPath) {
      data.videoUrl = await this.s3FileUpload(
        {
          key: data.file.fileFullPath.name,
          type: 'videos.video',
          mediaType: data.file.fileFullPath.type,
        },
        data.file.fileFullPath,
        uploadProgressCallback,
      );
    }
    delete data.file;
    return api.patch(`/videos/direct-upload/${id}`, data);
  },
  deleteVideo(id: string) {
    return api.delete(`/videos/${id}`);
  },
  uploadAffiliateContent(data: any, uploadProgressCallback?: Function) {
    return this.s3FileUpload(
      {
        key: data.file.name,
        type: 'affiliateContent.video',
        mediaType: data.file.type,
      },
      data.file,
      uploadProgressCallback,
    ).then((res) => {
      if (res) {
        delete data.file;
        data.videoUrl = res;
        return api.post('/affiliateContent/direct-upload', data);
      } else {
        return Promise.reject();
      }
    });
  },
  updateAffiliateContent(id, data: FormData, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return api.put(`/affiliateContent/${id}`, data, config);
  },
  async updateAffiliateContentDirectUpload(id, data: any, uploadProgressCallback?: Function) {
    if (data.file) {
      data.videoUrl = await this.s3FileUpload(
        {
          key: data.file.name,
          type: 'affiliateContent.video',
          mediaType: data.file.type,
        },
        data.file,
        uploadProgressCallback,
      );
    }
    delete data.file;
    return api.patch(`/affiliateContent/direct-upload/${id}`, data);
  },
  deleteAffiliateContent(id: string) {
    return api.delete(`/affiliateContent/${id}`);
  },
  checkUsername(username: string) {
    return api.post('/performers/check-performer-username', {username});
  },
  uploadWelcomeVideo(data: {welcomeVideo: string}) {
    return api.post('/performers/welcome-video', data);
  },
  uploadWelcomePhoto(data: {welcomePhoto: string}) {
    return api.post('/performers/welcome-photo', data);
  },
  getEarnings(query: string | undefined) {
    return api.get(`/earning/search` + (query ? `?${query}` : ''));
  },
  getPayoutHistory(query: string | undefined) {
    return api.get(`/get-payout-history` + (query ? `?${query}` : ''));
  },
  getEarningsByPeriod(query: string | undefined) {
    return api.get(`/earning/search/groupedByPeriod` + (query ? `?${query}` : ''));
  },
  getPreSignedPostData(data: PreSignedPostInterface, file: File, config) {
    return api.post('/utils/get-pre-signed-post-data', data).then((res) => {
      const formData = new FormData();
      Object.keys(res.data.fields).forEach((key) => {
        formData.append(key, res.data.fields[key]);
      });
      formData.append('file', file);
      return axios.post(res.data.url, formData, config).then(() => {
        return res.data.fileToken;
      });
    });
  },
  async s3FileUpload(data: PreSignedPostInterface, file: File, uploadProgressCallback?: Function) {
    const {urls, key, fileToken, chunkSize, uploadId} = await api
      .post(`/utils/s3-multi-upload/init`, {
        key: data.key,
        mediaType: data.mediaType,
        type: data.type,
        fileSize: file.size,
        chunkSize: 10 * 1024 * 1024,
      })
      .then((res) => res.data);
    let url;
    let start = 0;
    const parts: Array<{url: string; slice: Blob; progressEvent: Function}> = [];
    let totalProgress = 0;
    while ((url = urls.shift())) {
      let loaded = 0;
      parts.push({
        url: url,
        slice: file.slice(start, Math.min(file.size, start + chunkSize)),
        progressEvent: function progressEvent(event) {
          if (uploadProgressCallback) {
            totalProgress += event.loaded - loaded;
            loaded = event.loaded;
            uploadProgressCallback({loaded: totalProgress, total: file.size});
          }
        },
      });
      start += chunkSize;
    }
    if ((await this.s3FileUploadParts(parts)) === 'success') {
      return api.post(`/utils/s3-multi-upload/complete`, {key: key, uploadId}).then(() => {
        return fileToken;
      });
    }
    return api.post(`/utils/s3-multi-upload/abort`, {key: key, uploadId}).then(() => {
      return null;
    });
  },
  async s3FileUploadParts(parts: Array<{url: string; slice: Blob; progressEvent: Function}>) {
    const failedParts: Array<{url: string; slice: Blob; progressEvent: Function}> = [];
    const promises: Array<Promise<any>> = [];
    for (let i = 0; i <= parts.length - 1; i++) {
      promises.push(
        this.s3Upload(parts[i].url, parts[i].slice, parts[i].progressEvent).catch(() => {
          failedParts.push(parts[i]);
        }),
      );
      if (promises.length === 10) {
        await Promise.all(promises);
        promises.splice(0, promises.length);
      }
      if (vxm.model.abortUpload) {
        break;
      }
    }
    await Promise.all(promises);
    if (vxm.model.abortUpload) {
      return 'abort';
    } else if (failedParts.length) {
      vxm.model.setShowUploadErrorModal(true);
      if (await vxm.model.uploadErrorAction) {
        return this.s3FileUploadParts(failedParts);
      } else {
        return 'error';
      }
    }
    return 'success';
  },
  s3Upload(url, slice, progressCallback: Function) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.onload = function() {
        if (this.status === 200) {
          resolve();
        } else {
          reject();
        }
      };
      xhr.onerror = function() {
        reject();
      };
      xhr.upload.onprogress = function(event) {
        progressCallback(event);
        if (vxm.model.abortUpload) {
          xhr.abort();
          reject();
        }
      };
      xhr.send(slice);
    });
  },
  connectTwitter(params: {callbackUrl: string}) {
    return api.get('/twitter/connect', {
      params,
    });
  },
  disconnectTwitter() {
    return api.delete('/twitter/connect');
  },
  tweetClip(data: {_id: string; tweetText: string}) {
    return api.post(`/twitter/share/clip/${data._id}`, {tweetText: data.tweetText});
  },
  createBulkMessage(data: {content: string; subscribersType: string}) {
    return api.post('/messages-to-subscribers', data);
  },
  createBulkImageMessage(data: {file: File; subscribersType: string}, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return this.getPreSignedPostData(
      {
        key: data.file.name,
        type: 'messages.image',
        mediaType: data.file.type,
      },
      data.file,
      config,
    ).then((res) => {
      return api.post('/messages-to-subscribers/image', {content: res, subscribersType: data.subscribersType});
    });
  },
  createBulkVideoMessage(data: {file: File; subscribersType: string}, uploadProgressCallback?: Function) {
    const config = {} as any;
    if (uploadProgressCallback) {
      config.onUploadProgress = function(progressEvent) {
        uploadProgressCallback(progressEvent);
      };
    }
    return this.getPreSignedPostData(
      {
        key: data.file.name,
        type: 'messages.video',
        mediaType: data.file.type,
      },
      data.file,
      config,
    ).then((res) => {
      return api.post('/messages-to-subscribers/video', {content: res, subscribersType: data.subscribersType});
    });
  },
  getSubscribersCount(): Promise<AxiosResponse<SubscribersCountInterface>> {
    return api.get('/messages/subscribers-total-by-type');
  },
  sendPayoutRequest() {
    return api.post('/send-payout-request');
  },
};
